import React, { useState } from 'react';
import { Grid, TextField } from '@mui/material';
import socket from '../../Socket';
import { useNavigate } from 'react-router-dom';

const Formulario = () => {
    const [name, setName] = useState("");
    const [dni, setDni] = useState("");
    const navigate = useNavigate();
    const [isValidDni, setIsValidDni] = useState(false); // Estado para validar el DNI

    const handleChangeDni = event => {
        const inputDni = event.target.value;

        // Permitir escribir en el campo pero validar si tiene entre 7 y 11 dígitos
        setDni(inputDni);
        setIsValidDni(/^\d{7,11}$/.test(inputDni)); // Solo es válido si cumple la condición

        if (/^\d{7,11}$/.test(inputDni)) {
            socket.emit("consultarCliente", { dniocuit: inputDni }, (callback) => {
                if (callback.length === 0 || callback[0].nombre === "null") {
                    setName("");
                } else {
                    setName(callback[0].nombre);
                    localStorage.setItem('user', JSON.stringify(callback[0]));
                }
            });
        }
    };

    const handleChangeName = event => {
        setName(event.target.value);
    };

    return (
        <Grid container justifyContent="center" alignItems="center" style={{ height: "100vh", backgroundColor: "#fff" }}>
            <Grid item xs={12}>
                <Grid container display="flex" justifyContent="center" bgcolor="white" style={{ padding: "2rem" }}>
                    <Grid item xs={11} style={{ textAlign: "center", marginBottom: "1.5rem" }}>
                        <img src={require("../../IMAGES/LOGO.webp")} alt="Logo" />
                    </Grid>
                    <p style={{ fontWeight: "400", color: "red", fontSize: "18px" }}>SOLICITUD TURNO</p>

                    {/* Input DNI */}
                    <Grid item xs={11}>
                        <TextField
                            type="number"
                            placeholder="Ingresar DNI"
                            fullWidth
                            style={{ backgroundColor: "white" }}
                            value={dni}
                            onChange={handleChangeDni}
                        />
                    </Grid>

                    {/* Input Nombre */}
                    <Grid item xs={11} mt={2}>
                        <TextField
                            type="text"
                            placeholder="Ingresar Nombre y Apellido"
                            onChange={handleChangeName}
                            style={{ backgroundColor: "white" }}
                            value={name}
                            fullWidth
                        />
                    </Grid>

                    {/* Botón SIGUIENTE con validación */}
                    <Grid item xs={12} mt={2} display="flex" justifyContent="center">
                        {name.length > 0 && isValidDni ? (
                            <Grid
                                style={{ 
                                    backgroundColor: "#c2061e", 
                                    color: "white", 
                                    width: "92%", 
                                    textAlign: "center", 
                                    padding: "10px", 
                                    cursor: "pointer" 
                                }}
                                onClick={() => {
                                    if (!localStorage.getItem('user')) {
                                        localStorage.setItem('user', JSON.stringify({
                                            nombre: name,
                                            dniocuit: dni,
                                            prioritario: "No"
                                        }));
                                    }
                                    navigate("/solicitar-turno/seleccion-turno");
                                }}
                            >
                                SIGUIENTE
                            </Grid>
                        ) : (
                            <Grid 
                                style={{ 
                                    backgroundColor: "gray", 
                                    color: "white", 
                                    width: "92%", 
                                    textAlign: "center", 
                                    padding: "10px" 
                                }}
                            >
                                SIGUIENTE
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Formulario;
